<template>
	<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.1803 8.9C10.3531 8.97754 10.4741 9.02061 10.5259 9.09815C10.5778 9.17569 10.5778 9.51169 10.4309 9.92523C10.284 10.3129 9.57533 10.6834 9.25557 10.7351C8.93582 10.7868 8.57285 10.8126 8.1494 10.6834C7.90742 10.5886 7.56174 10.4852 7.14693 10.3129C5.40989 9.55477 4.27779 7.79723 4.18272 7.67662C4.10495 7.58185 3.47408 6.74615 3.47408 5.89323C3.47408 5.04031 3.91482 4.62677 4.08766 4.45446C4.2605 4.28215 4.43334 4.23046 4.55433 4.23046H4.90001C4.99507 4.23046 5.14198 4.17877 5.2889 4.52338C5.40989 4.89385 5.78149 5.72092 5.80742 5.81569C5.85927 5.91046 5.8852 6.01385 5.83334 6.10862C5.78149 6.22923 5.73828 6.30677 5.6605 6.40154C5.56544 6.49631 5.46174 6.64277 5.3926 6.72031C5.32347 6.79785 5.21976 6.89262 5.31482 7.06492C5.40989 7.23723 5.78149 7.82308 6.29137 8.26246C6.9568 8.87415 7.51853 9.04646 7.69137 9.14123C7.86421 9.21877 7.95927 9.21877 8.06298 9.08954C8.15804 8.96892 8.50372 8.57262 8.62471 8.40892C8.71977 8.23662 8.8494 8.26246 9.0136 8.31415C9.16051 8.39169 10.0161 8.80523 10.1889 8.9H10.1803ZM11.9432 2.52462C10.621 1.23231 8.88397 0.5 7.0173 0.5C3.18025 0.5 0.04321 3.60154 0.04321 7.43538C0.04321 8.65877 0.362964 9.85631 0.976545 10.9074L0 14.5L3.69877 13.5437C4.70124 14.1037 5.85063 14.3708 7.00866 14.3708H7.03458C10.8543 14.3708 13.9914 11.2692 13.9914 7.43538C13.9914 5.58308 13.2568 3.84277 11.9605 2.52462H11.9432Z"
			fill="currentColor"
		/>
	</svg>
</template>
